import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal } from 'react-bootstrap';
import {
    get_schemes, get_departments, get_schemes_by_fy, get_financial_year, AddAllocateBudget, UploadAllocateBudgetDoc, getAllocateBudgetList, get_departmentDetail, getActivity, getSubActivity
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { ADD_CHALLAN_FAIL } from "../../../RTK/Slices/ChallanSlice";
import './AllocateBudget.css';
import AddBudgetPreview from '../../../components/Multi/Model/AddBudgetPreview';
import { DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
const AllocateBudget = () => {
    const navigate = useNavigate();
    const { id, approveId } = useParams();
    const [showPreview, setShowPreview] = useState(false);
    const [fy_list, set_fy_list] = useState([]);
    const [schemeList, setSchemesList] = useState([]);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const [scheme, setScheme] = useState({});
    const [subHead, setSubHead] = useState([]);
    const [department, setDepartment] = useState([]);
    const [toggle, setToggle] = useState(false);
    const [change, setChange] = useState(false);
    const [disable, setDisable] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const { authData } = useSelector((state) => state.authData);
    const [subheadBudget, setSubheadBudget] = useState([]);
    const subheadBudgetUsed = useRef([]);
    const [departmentCount, setDepartmentCount] = useState(1);
    const [subheadCount, setSubheadCount] = useState(1);
    const [selectedDepartment, setSelectedDepartment] = useState({});
    const [selectedSubhead, setSelectedSubhead] = useState({});
    const subheadBudgetTotal = useRef({
        allocateProvision: 0,
        allocateRelease: 0,
        schemaProvision: 0,
        schemaRelease: 0,
    });
    const [formData, setFormData] = useState({
        budget_order_no: "",
        budget_order_date: "",
        provision: 0,
        release: 0,
        scheme_id: null,
        financial_year_id: null,
        supporting_attachment: [],
        order_attachment: [],
        l1_remark: "",
        l2_remark: "",
        l3_remark: "",
        total_scheme_provisional_allocation: 0,
        total_scheme_released_allocation: 0,
        subheads: [],
        department_Array: [
            {
                is_scheme_account_active: true,
                department_id: "",
                department_level: "",
                department_name: "",
                subHead: [{
                    subhead_id: "",
                    name: "",
                    code: "",
                    provisional_budget: "",
                    release_budget: 0,
                    balance: 0,
                    utilised_budget: 0,
                    approveBudget: 0,
                    santioned: 0,
                    release_Balance: 0,
                    release_Balance: 0,
                    activity: [{
                        activity_id: '', subactivity_id: '', provisional_budget: "",
                        budget: "", subhead_provisional_budget: 0, subhead_budget: 0
                    }],
                    activities: [],
                }],
            }],
        parent_department_id: authData.user.department_id,
        budget_order_doc_url: "",
        budget_supporting_doc_url: "",
    });
    const fetchFinancialYears = async () => {
        const fy = await get_financial_year();
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
    }
    const dispatch = useDispatch();
    useEffect(() => {
        if (id) {
            fetchActivity();
            fetchSubActivity();
            fetchDepartments();
            setDisable(true);
            fetchSchemes({});
            fetchFinancialYears();
            fetchAllocateBudget();
        }
        else {
            fetchActivity();
            fetchSubActivity();
            fetchSchemes({});
            fetchDepartments();
            fetchFinancialYears();
        }
    }, [])
    const handleFormData = (e) => {
        let year;
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'financial_year_id') {
            // year = e.target.options[e.target.selectedIndex].text
            setFormData({
                ...formData,
                [name]: value,
                // financial_year: year
            });
        }
        setFormData({
            ...formData,
            [name]: value,
            // financial_year: year
        });
    }
    const handleSubheadBudgetFormData = (departmentIndex, subheadIndex, activityIndex, e) => {
        const name = e.target.name;
        const value = e.target.value;
        let totalProvisionalBudget = 0;
        let totalBudget = 0;
        formData.department_Array[departmentIndex].subHead[subheadIndex].activity.forEach(item => {
            totalProvisionalBudget += parseFloat(item.provisional_budget || 0);
            totalBudget += parseFloat(item.budget || 0);
        });
        let updatedDepartments = [...formData.department_Array];
        const newActivity = [...updatedDepartments[departmentIndex].subHead[subheadIndex].activity];
        const updatedActivity = { ...newActivity[activityIndex] };
        if (name === 'provisional_budget') {
            totalProvisionalBudget = totalProvisionalBudget + Number(value);
            if (totalProvisionalBudget >= formData.department_Array[departmentIndex].subHead[subheadIndex][name]) {
                return alert('Total provisional budget exceeds the allowed amount.');
            }
            let activityBalance = formData.department_Array[departmentIndex].subHead[subheadIndex].activities.find(e => e.subhead_activity_id == newActivity[activityIndex].activity_id && e.subhead_subactivity_id == newActivity[activityIndex].subactivity_id);
            if (Number(activityBalance.provisional_budget) < Number(value)) {
                return alert('Total provisional budget exceeds the provisional allowed amount. in activity');
            }

        }
        if (name === 'budget') {
            totalBudget = totalBudget + Number(value);
            if (totalBudget >= formData.department_Array[departmentIndex].subHead[subheadIndex][name]) {
                return alert('Total budget exceeds the allowed amount.');
            }
            let activityBalance = formData.department_Array[departmentIndex].subHead[subheadIndex].activities.find(e => e.subhead_activity_id == newActivity[activityIndex].activity_id && e.subhead_subactivity_id == newActivity[activityIndex].subactivity_id);
            if (Number(activityBalance.budget) < Number(value)) {
                return alert('Total released budget exceeds the released allowed amount. in activity');
            }
        }
        updatedActivity[name] = value;
        newActivity[activityIndex] = updatedActivity;
        updatedDepartments[departmentIndex].subHead[subheadIndex] = {
            ...updatedDepartments[departmentIndex].subHead[subheadIndex],
            activity: newActivity
        };
        let provisionBudget = 0;
        let releaseBudget = 0;
        newActivity.forEach(activity => {
            provisionBudget += Number(activity.provisional_budget) || 0;
            releaseBudget += Number(activity.budget) || 0;
        });
        updatedDepartments[departmentIndex].subHead[subheadIndex] = {
            ...updatedDepartments[departmentIndex].subHead[subheadIndex],
            allocate_provision: provisionBudget,
            allocate_release: releaseBudget,
            activity: newActivity
        };
        setFormData(prevState => ({
            ...prevState,
            department_Array: updatedDepartments
        }));
        calculateFinalTotal();
        // console.log(formData.department_Array, subheadBudgetTotal);
    }

    const calculateFinalTotal = () => {
        const totals = formData.department_Array.reduce((accum, department) => {
            const departmentTotal = department.subHead.reduce((subheadTotal, subhead) => {
                const provisionalBudget = parseFloat(subhead.allocate_provision) || 0;
                const provisionalRelease = parseFloat(subhead.allocate_release) || 0;
                // return activityTotal + provisionalBudget;
                // const subheadTotalProvisional = subhead.activity.reduce((activityTotal, activity) => {
                //     const provisionalBudget = parseFloat(activity.provisional_budget) || 0;
                //     return activityTotal + provisionalBudget;
                // }, 0);
                // const subheadTotalRelease = subhead.activity.reduce((activityTotal, activity) => {
                //     const releaseBudget = parseFloat(activity.budget) || 0;
                //     return activityTotal + releaseBudget;
                // }, 0);
                return {
                    provisional_budget: subheadTotal.provisional_budget + provisionalBudget,
                    release_budget: subheadTotal.release_budget + provisionalRelease,
                };
            }, { provisional_budget: 0, release_budget: 0 });

            accum.provisional_budget += departmentTotal.provisional_budget;
            accum.release_budget += departmentTotal.release_budget;

            return accum;
        }, { provisional_budget: 0, release_budget: 0 });
        
        subheadBudgetTotal.current = {
            allocateProvision: totals.provisional_budget,
            allocateRelease: totals.release_budget
        }
        console.log('totalCalcuulated', subheadBudgetTotal.current)
    }

    const changeSubheadBudget = (departmentIndex, subheadIndex, e) => {
        const name = e.target.name;
        const value = e.target.value;
        let updatedDepartments = [...formData.department_Array];
        console.log('departments', updatedDepartments);
        const newSubhead = updatedDepartments[departmentIndex].subHead[subheadIndex];
        console.log('subheadChange', newSubhead);
        if(name == 'allocate_release' && newSubhead.balance < value) {
            newSubhead[name] = 0;
            updatedDepartments[departmentIndex].subHead[subheadIndex] = newSubhead;
            setFormData(prevState => ({
            ...prevState,
                department_Array: updatedDepartments
            }));
            calculateFinalTotal();
            return alert('Released budget exceeds the release balance.');
        }else {
            newSubhead[name] = value;
            updatedDepartments[departmentIndex].subHead[subheadIndex] = newSubhead;
            setFormData(prevState => ({
            ...prevState,
                department_Array: updatedDepartments
            }));
            calculateFinalTotal();
        }
        
    }


    const changeToggle = async (value) => {
        if (!validateForm()) {
            alert('Please fill scheme code and financial year.');
            return;
        }
        setToggle(value)
        await schemeDetailByfy(formData.scheme_id, formData.financial_year_id);
    }
    const validateForm = () => {
        if (!formData.scheme_id || !formData.financial_year_id) {
            return false;
        }
        return true;
    };
    const validateInputForm = () => {
        const fields = [
            { key: 'budget_order_no', label: 'Budget Order No' },
            { key: 'budget_order_date', label: 'Budget Order Date' },
            { key: 'order_attachment', label: 'Order Attachment', validate: value => value && value.length > 0 },
            { key: 'provision', label: 'Provision', validate: value => value !== null && value !== undefined },
            { key: 'release', label: 'Release', validate: value => value !== null && value !== undefined },
            { key: 'l1_remark', label: 'L1 Remark' }
        ];
        let missingFields = fields.filter(field => {
            const value = formData[field.key];
            const isValid = field.validate ? field.validate(value) : !!value;
            return !isValid;
        }).map(field => field.label);

        if (missingFields.length > 0) {
            return alert('Please fill the following required fields: ' + missingFields.join(', '));
        }
        const provision = Number(formData.provision);
        const release = Number(formData.release);
        if ((provision == 0 && release === 0)) {
            return alert('At least one of Provision or Release must be greater than 0 and the other should be 0.');
        }
        if ((subheadBudgetTotal.current.allocateProvision == 0 && subheadBudgetTotal.current.allocateRelease == 0)) {
            return alert('At least one of allocateProvision or allocateRelease must be greater than 0 and the other should be 0.');
        }
        if (provision < Number(subheadBudgetTotal.current.allocateProvision)) {
            return alert('Total provisional budget exceeds the allowed amount.');
        }
        if (release < Number(subheadBudgetTotal.current.allocateRelease)) {
            return alert('Total release budget exceeds the allowed amount.');
        }
        return true;
    };
    const handleConfirm = (value) => {
        setShowConfirm(value)
        setChange(true)
    }
    const confirmSubmit = () => {
        setShowConfirm(false)
        setFormData({
            budget_order_no: "",
            budget_order_date: "",
            provision: 0,
            release: 0,
            scheme_id: null,
            financial_year_id: null,
            supporting_attachment: [],
            order_attachment: [],
            l1_remark: "",
            l2_remark: "",
            l3_remark: "",
            total_scheme_provisional_allocation: 0,
            total_scheme_released_allocation: 0,
            subheads: [],
            department_Array: [
                {
                    is_scheme_account_active: true,
                    department_id: "",
                    department_level: "",
                    department_name: "",
                    subHead: [{
                        subhead_id: "",
                        name: "",
                        code: "",
                        provisional_budget: "",
                        release_budget: 0,
                        balance: 0,
                        utilised_budget: 0,
                        approveBudget: 0,
                        santioned: 0,
                        release_Balance: 0,
                        release_Balance: 0,
                        activity: [{
                            activity_id: '', subactivity_id: '', provisional_budget: "",
                            budget: "", subhead_provisional_budget: 0, subhead_budget: 0
                        }],
                        activities: [],
                    }],
                }],
            parent_department_id: authData.user.department_id,
            budget_order_doc_url: "",
            budget_supporting_doc_url: "",
        });
        setToggle(false);
        setChange(false);
    }
    const fetchSchemes = async () => {
        try {
            const resp = await get_schemes({ approved: true });
            if (resp.data.status) {
                setSchemesList(resp.data.schemes);
            }
        } catch (error) {
            setSchemesList([]);
        }
    }
    const schemeDetailByfy = async (scheme_id, financial_year_id) => {
        try {
            const resp = await get_schemes_by_fy(scheme_id, financial_year_id);
            if (resp.data.status) {
                if (resp.data.schemes.length != 0) {
                    setScheme(resp.data.schemes[0]);
                    await fetchDepartments(resp.data.schemes[0].id);
                    let subheadList = [];
                    let subheadListTotal = [];
                    if (id) {
                        if (resp.data.schemes[0]?.sub_heads_list.length != 0) {
                            resp.data.schemes[0]?.sub_heads_list.forEach((item, index) => {
                                let updatedDepartment = department.map(dept => {
                                    const allocateData = subheadBudget.find(detail => detail.subhead_id === item.id && detail.department_id === dept.id);
                                    if (allocateData) {
                                        return {
                                            ...dept,
                                            provisional_budget: allocateData.provisional_budget,
                                            budget: allocateData.budget
                                        };
                                    } else {
                                        return dept;
                                    }
                                });
                                item.department = updatedDepartment;
                                subheadList.push({ subheadId: item.id, provisionalBudget: 0, releasedBudget: 0 });
                                subheadListTotal.push({ subheadId: item.id, release_Balance: 0, provision_Balance: 0 });
                            });
                            subheadBudgetUsed.current = subheadList;
                            subheadBudgetUsed.current.forEach(e => {
                                e.provisionalBudget = 0;
                                e.releasedBudget = 0;
                                subheadBudget.forEach(x => {
                                    if (x.subhead_id == e.subheadId) {
                                        e.provisionalBudget += Number(x.provisional_budget)
                                        e.releasedBudget += Number(x.budget)
                                    }
                                })
                            })
                            subheadBudgetTotal.current = { allocateProvision: formData.total_scheme_provisional_allocation, allocateRelease: formData.total_scheme_released_allocation };
                        }
                    }
                    else {
                        // console.log('m in else');
                        if (resp.data.schemes[0]?.sub_heads_list.length != 0) {
                            resp.data.schemes[0]?.sub_heads_list.forEach((item, index) => {
                                subheadList.push({ subheadId: item.id, provisionalBudget: 0, releasedBudget: 0 });
                                subheadListTotal.push({ subheadId: item.id, release_Balance: 0, provision_Balance: 0 });
                            });
                            subheadBudgetUsed.current = subheadList;
                        }
                    }
                    // sub_heads_list
                    // subheadBudgetUsed.current = resp.data.schemes[0].sub_heads_list;
                    setSubheadCount(resp.data.schemes[0].sub_heads_list.length - 1);
                    setSubHead(resp.data.schemes[0].sub_heads_list);
                    const result = {};
                    resp.data.schemes[0].sub_heads_list.forEach((_, index) => {
                        result[`subhead${index}`] = "";
                    });
                    setSelectedSubhead(result);
                }
            }
        } catch (error) {
            // console.log(error);
            setScheme({})
        }
    }
    const handleAttachmentChange = (event, type) => {
        if (validateFile(event.target)) {
            const files = Array.from(event.target.files);
            setFormData({
                ...formData,
                [type]: files
            });
        }
    };
    function validateFile(input) {
        // console.log('input', input);
        const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
        const maxSize = 5 * 1024 * 1024; // 5 MB

        const file = input.files[0];
        const fileSize = file.size;
        const fileType = file.type;

        if (!allowedTypes.includes(fileType)) {
            // document.getElementById('error').innerText = "Invalid file type. Please upload a PDF or DOCX file.";
            toast.error('File Type Not Allowed', {
                position: toast.POSITION.TOP_CENTER
            });
            input.value = ''; // Clear the input
            return false;
        }

        if (fileSize > maxSize) {
            toast.error('File size greater than 5MB', {
                position: toast.POSITION.TOP_CENTER
            });
            input.value = '';
            return false;
        }
        return true;
    }
    const handleSubmit = async (e) => {
        if (!id) {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault();
            }
        }
        try {
            if (id) {
                // console.log("edit logic implement");
                // console.log(formData);
                // anand sir add api for edit allocate budget
            }
            else {
                const order_attachment = new FormData();
                order_attachment.append('attachment[]', formData.order_attachment[0]);
                const firstRes = await UploadAllocateBudgetDoc(order_attachment);
                let secondRes;
                if (formData.supporting_attachment[0]) {
                    const supporting_attachment = new FormData();
                    supporting_attachment.append('attachment[]', formData.supporting_attachment[0]);
                    secondRes = await UploadAllocateBudgetDoc(supporting_attachment);
                }


                if (firstRes.data.status) {
                    const payload = {
                        ...formData,
                        total_scheme_provisional_allocation: subheadBudgetTotal.current.allocateProvision,
                        total_scheme_released_allocation: subheadBudgetTotal.current.allocateRelease,
                        budget_order_doc_url: firstRes.data.result[0],
                        budget_supporting_doc_url: secondRes?.data?.result[0] || '',
                        total_departments: formData.department_Array.length,
                    };
                    const response = await AddAllocateBudget(payload);
                    if (response.data.status) {
                        // rajat please add close popup code and show success or error message
                        setShowPreview(false);
                        navigate('/allocate-budgets');
                    }
                }
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating department'));
        }
    }
    const fetchDepartments = async (scheme_id = null) => {
        try {
            let newDepartmentLevel = authData.user.department_details.department_level;
            if (newDepartmentLevel === "T1") {
                newDepartmentLevel = "T2";
            } else if (newDepartmentLevel === "T2") {
                newDepartmentLevel = "T3";
            }
            const departments = await get_departments({ department_level: newDepartmentLevel, active_scheme_account_id: scheme_id });
            if (departments.data.status) {
                setDepartmentCount(departments.data.result.length - 1);
                setDepartment(departments.data.result);
                const result = {};
                departments.data.result.forEach((_, index) => {
                    result[`department${index}`] = "";
                });
                setSelectedDepartment(result);
            }
        } catch (error) {
            console.log("departments", error);
        }
    }
    const fetchAllocateBudget = async () => {
        try {
            setToggle(true);
            let subheadArray = [];
            let newDepartmentLevel = authData.user.department_details.department_level;
            if (newDepartmentLevel === "T1") {
                newDepartmentLevel = "T2";
            } else if (newDepartmentLevel === "T2") {
                newDepartmentLevel = "T3";
            }
            const departments = await get_departments({ department_level: newDepartmentLevel });
            const { data } = await getAllocateBudgetList({ id: id });
            let totalProvisionalBudgetOverall = 0;
            let totalReleaseBudgetOverall = 0;
            let department_Array = [];
            const resp = await get_schemes_by_fy(data.result[0].scheme_id, data.result[0].financial_year_id);
            if (resp.data.status) {
                if (resp.data.schemes.length != 0) {
                    setScheme(resp.data.schemes[0]);
                    setSubHead(resp.data.schemes[0].sub_heads_list);
                    subheadArray = resp.data.schemes[0].sub_heads_list;
                }
            }
            data.result[0].budget_details.forEach(item => {
                let departmentObj = departments.data.result.find(d => d.id === item.department_id);
                let departmentCheck = department_Array.find(d => d.department_id === item.department_id);
                if (!departmentCheck) {
                    departmentCheck = {
                        department_id: item?.department_id,
                        department_level: departmentObj?.department_level,
                        department_name: departmentObj?.name,
                        subHead: []
                    };
                    department_Array.push(departmentCheck);
                }
                let totalProvisionalBudget = 0;
                let totalReleaseBudget = 0;
                const subheadActivity = subheadArray.find(d => d.id === item.subhead_id);
                const activities = item.activities.map(activity => {
                    const subheadItems = subheadActivity.activities.find(d => d.subhead_activity_id === activity.subhead_activity_id && d.subhead_subactivity_id === activity.subhead_subactivity_id);
                    totalProvisionalBudget += parseFloat(activity.provisional_budget);
                    totalReleaseBudget += parseFloat(activity.budget); // Assuming 'budget' is the release budget
                    return {
                        activity_id: activity.subhead_activity_id,
                        subactivity_id: activity.subhead_subactivity_id,
                        provisional_budget: activity.provisional_budget,
                        budget: activity.budget,
                        subhead_provisional_budget: subheadItems.provisional_budget,
                        subhead_budget: subheadItems.budget,
                    };
                });
                const subHead = {
                    id: item.id,
                    subhead_id: item.subhead_id,
                    name: item.name,
                    code: item.code,
                    provisional_budget: item.provisional_budget,
                    release_budget: 0,
                    approveBudget: 0,
                    santioned: 0,
                    release_Balance: 0,
                    balance: item.balance,
                    allocate_provision: totalProvisionalBudget,
                    allocate_release: totalReleaseBudget,
                    activity: activities,
                    activities: item.activities,
                    allocated_budget_id: item.allocated_budget_id
                };
                departmentCheck.subHead.push(subHead);
                totalProvisionalBudgetOverall += parseFloat(totalProvisionalBudget);
                totalReleaseBudgetOverall += parseFloat(totalReleaseBudget);
            });
            setFormData({
                id: data.result[0].id,
                budget_order_no: data.result[0].budget_order_no,
                budget_order_date: data.result[0].budget_order_date,
                scheme_id: data.result[0].scheme_id,
                financial_year_id: data.result[0].financial_year_id,
                supporting_attachment: [],
                order_attachment: [],
                provision: data.result[0]?.total_scheme_provisional_allocation,
                release: data.result[0]?.total_scheme_released_allocation,
                l1_remark: data.result[0].l1_remark,
                l2_remark: data.result[0].l2_remark,
                l3_remark: data.result[0].l3_remark,
                l2_status: data.result[0].l2_status,
                l3_status: data.result[0].l3_status,
                total_scheme_provisional_allocation: data.result[0].total_scheme_provisional_allocation,
                total_scheme_released_allocation: data.result[0].total_scheme_provisional_allocation,
                subheads: [],
                budget_order_doc_url: data.result[0].budget_order_doc_url,
                budget_supporting_doc_url: data.result[0].budget_supporting_doc_url,
                department_Array: department_Array,
            })
            subheadBudgetTotal.current = {
                allocateProvision: totalProvisionalBudgetOverall,
                allocateRelease: totalReleaseBudgetOverall
            };
        } catch (error) {
            console.log("allocate budget", error);
        }
    }
    const previewhandler = (e) => {
        if (validateInputForm()) {
            e.preventDefault();
            setFormData((prevState) => {
                const newDepartmentArray = [...prevState.department_Array];
                newDepartmentArray.forEach(department => {
                    department.subHead.forEach(subhead => {
                        subhead.activity = subhead.activity.filter(activity => 
                            activity.activity_id !== "" || activity.subactivity_id !== ""
                        );
                    });
                });
                return {
                    ...prevState,
                    department_Array: newDepartmentArray
                };
            });
            setShowPreview(true);
        }
    };
    const renderMessage = () => {
        if (id) {
            return <h4>Edit Allocate Budget</h4>;
        }
        else if (approveId) {
            return <h4>Approve Allocate Budget</h4>;
        }
        else {
            return <h4>Allocate Budget</h4>;
        }
    }
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const addNewActivity = (index) => {
        const newSubHeads = [...formData.department_Array[index].subHead].map(subHead => {
            return {
                ...subHead,
                activity: [
                    ...subHead.activity,
                    {
                        activity_id: '',
                        subactivity_id: '',
                        provisional_budget: 0,
                        budget: 0,
                        subhead_provisional_budget: 0, subhead_budget: 0
                    }
                ]
            };
        });
        setFormData(prevState => {
            const newDepartmentArray = [...prevState.department_Array];
            newDepartmentArray[index].subHead = newSubHeads;
            return {
                ...prevState,
                department_Array: newDepartmentArray
            };
        });
    };
    const deleteNewActivity = (departmentIndex, subHeadIndex, activityIndex) => {
        const updatedDepartments = [...formData.department_Array];
        const newSubHeads = [...updatedDepartments[departmentIndex].subHead];
        const newActivities = [...newSubHeads[subHeadIndex].activity];
        if (newActivities.length > 1) {
            newActivities.splice(activityIndex, 1);
            newSubHeads[subHeadIndex] = {
                ...newSubHeads[subHeadIndex],
                activity: newActivities,
            };
            updatedDepartments[departmentIndex] = {
                ...updatedDepartments[departmentIndex],
                subHead: newSubHeads,
            };
            setFormData(prevState => ({
                ...prevState,
                department_Array: updatedDepartments,
            }));
        }
    };
    const handleAddSubHead = (index) => {
        const newSubHeads = [...formData.department_Array[index].subHead];
        newSubHeads.push({
            subhead_id: "",
            name: "",
            code: "",
            provisional_budget: 0,
            release_budget: 0,
            approveBudget: 0,
            santioned: 0,
            release_Balance: 0,
            activity: [{
                activity_id: '', subactivity_id: '', provisional_budget: "", budget: "", subhead_provisional_budget: 0, subhead_budget: 0
            }],
            activities: [],
        });
        const updatedDepartments = [...formData.department_Array];
        updatedDepartments[index] = {
            ...updatedDepartments[index],
            subHead: newSubHeads
        };
        setFormData({ ...formData, department_Array: updatedDepartments })
        setSubheadCount(subheadCount - 1);
    };
    const handleDeleteSubHead = (deptIndex) => {
        const newSubHeads = [...formData.department_Array[deptIndex].subHead];
        if (newSubHeads.length > 1) {
            newSubHeads.pop();
            const updatedDepartments = [...formData.department_Array];
            updatedDepartments[deptIndex] = {
                ...updatedDepartments[deptIndex],
                subHead: newSubHeads
            };
            setFormData({ ...formData, department_Array: updatedDepartments });
            setSubheadCount(subheadCount + 1);
        }
    };
    const handleAddDepartment = () => {
        const newDepartment = {
            is_scheme_account_active: true,
            department_id: "",
            department_level: "",
            department_name: "",
            subHead: [{
                subhead_id: "",
                name: "",
                code: "",
                provisional_budget: 0,
                release_budget: 0,
                approveBudget: 0,
                santioned: 0,
                release_Balance: 0,
                activity: [{
                    activity_id: '', subactivity_id: '', provisional_budget: 0, budget: 0, subhead_provisional_budget: 0, subhead_budget: 0
                }],
                activities: [],
            }],
        };
        const updatedDepartments = [...formData.department_Array, newDepartment];
        setFormData({
            ...formData,
            department_Array: updatedDepartments
        });
        setDepartmentCount(departmentCount - 1);
        setSelectedSubhead({});
    };
    const handleDeleteDepartment = () => {
        const updatedDepartments = [...formData.department_Array];
        if (updatedDepartments.length > 1) {
            updatedDepartments.pop();
            setFormData({
                ...formData,
                department_Array: updatedDepartments
            });
            setDepartmentCount(departmentCount + 1);
        }
    };
    const handleDepartmentChange = (departmentId, index, dropdownName) => {
        let departmentObj = department.find(detail => detail.id === departmentId);
        const updatedDepartment = { ...formData.department_Array[index] };
        console.log('updatedDep', updatedDepartment);
        updatedDepartment.department_name = departmentObj?.name || "";
        updatedDepartment.department_level = departmentObj?.department_level || "";
        updatedDepartment.department_id = departmentObj?.id;
        const updatedDepartments = [...formData.department_Array];
        updatedDepartments[index] = updatedDepartment;
        setFormData({
            ...formData,
            department_Array: updatedDepartments
        })
        const newValue = departmentId;
        setSelectedDepartment((prevState) => ({
            ...prevState,
            [dropdownName]: newValue,
        }));
        console.log(formData.department_Array);
    };
    const handleSubheadChange = (subheadId, subheadIndex, departmentIndex, dropdownName) => {
        console.log('called Subhead');
        let subheadObj = subHead.find(detail => detail.id === subheadId);
        console.log('subheadobj', subheadObj);
        if (!subheadObj) {
            console.error(`Subhead with ID ${subheadId} not found.`);
            return;
        }
        console.log('fd', formData.department_Array);
        const newSubHeads = [...formData.department_Array[departmentIndex].subHead];
        console.log('newSubheads', newSubHeads);
        const subheadValid = newSubHeads.find(item => item.subhead_id === subheadId)
        if (subheadValid) {
            return alert('subhead already selected');
        }
        const balance = calculateSubheadBalance(subheadObj.id, subheadObj.balance);
        const updatedSubhead = { ...newSubHeads[subheadIndex] };
        console.log('befoerUpdatedS', updatedSubhead);
        updatedSubhead.subhead_id = subheadObj.id;
        updatedSubhead.name = subheadObj.name;
        updatedSubhead.code = subheadObj.code | "";
        updatedSubhead.provisional_budget = subheadObj.provisional_budget | 0;
        updatedSubhead.budget = subheadObj.budget | 0;
        // updatedSubhead.balance = subheadObj.balance | 0;
        updatedSubhead.balance = balance | 0;
        updatedSubhead.utilised_budget = subheadObj.utilised_budget | 0;
        updatedSubhead.approveBudget = subheadObj?.approveBudget | 0;
        updatedSubhead.santioned = subheadObj?.santioned | 0;
        updatedSubhead.release_Balance = subheadObj?.release_Balance | 0;
        updatedSubhead.activities = [];
        subheadObj.activities.forEach((e, i) => {
            updatedSubhead.activities.push(e);
        })
        console.log('subheadObj', subheadObj.activities);
        updatedSubhead.activity = [{
            activity_id: '', subactivity_id: '', provisional_budget: 0, budget: 0, subhead_provisional_budget: 0, subhead_budget: 0
        }]
        // updatedSubhead.activities = [...updatedSubhead.activities, subheadObj.activities];
        console.log('updatedSubhead', updatedSubhead);
        newSubHeads[subheadIndex] = updatedSubhead;
        const updatedDepartments = [...formData.department_Array];
        updatedDepartments[departmentIndex] = {
            ...updatedDepartments[departmentIndex],
            subHead: newSubHeads
        };
        setFormData({
            ...formData,
            department_Array: updatedDepartments
        });
        const newValue = subheadId;
        setSelectedSubhead((prevState) => ({
            ...prevState,
            [dropdownName]: newValue,
        }));
        // console.log("department subhead update", formData.department_Array);
    };
    const handleActivityChange = (activityId, subactivityId, activityIndex, departmentIndex, subHeadIndex) => {
        let updatedDepartments = [...formData.department_Array];
        if (activityId && !subactivityId) {
            fetchSubActivity(activityId);
            let activityObj = activity.find(detail => detail.id === activityId);
            const newActivity = [...updatedDepartments[departmentIndex].subHead[subHeadIndex].activity];
            const updatedActivity = { ...newActivity[activityIndex] };
            updatedActivity.activity_id = activityObj?.id || "";
            newActivity[activityIndex] = updatedActivity;
            // console.log('new', newActivity);
            updatedDepartments[departmentIndex].subHead[subHeadIndex] = {
                ...updatedDepartments[departmentIndex].subHead[subHeadIndex],
                activity: newActivity
            };
        }
        else if (activityId && subactivityId) {
            console.log('m here');
            let subactivityObj = subActivity.find(detail => detail.id === subactivityId);
            const newActivity = [...updatedDepartments[departmentIndex].subHead[subHeadIndex].activity];
            const activities = [...updatedDepartments[departmentIndex].subHead[subHeadIndex].activities];
            const activityValid = newActivity.find(item => item.activity_id === activityId && item.subactivity_id === subactivityId);
            if (activityValid) {
                return alert('Sub-activity already selected');
            }
            const updatedActivity = { ...newActivity[activityIndex] };
            // console.log('dd', activities);
            // console.log('news02', updatedActivity);
            const activities_object = activities.find(act => act.subhead_activity_id == updatedActivity.activity_id && act.subhead_subactivity_id == subactivityObj?.id);
            updatedActivity.subactivity_id = subactivityObj?.id;
            updatedActivity.subhead_provisional_budget = activities_object?.provisional_budget;
            updatedActivity.subhead_budget = activities_object?.budget;
            newActivity[activityIndex] = updatedActivity;
            // console.log('news2', newActivity);
            updatedDepartments[departmentIndex].subHead[subHeadIndex] = {
                ...updatedDepartments[departmentIndex].subHead[subHeadIndex],
                activity: newActivity
            };
        }
        else {
            const newActivity = [...updatedDepartments[departmentIndex].subHead[subHeadIndex].activity];
            const updatedActivity = { ...newActivity[activityIndex] };
            updatedActivity.activity_id = "";
            updatedActivity.subactivity_id = "";
            newActivity[activityIndex] = updatedActivity;
            updatedDepartments[departmentIndex].subHead[subHeadIndex] = {
                ...updatedDepartments[departmentIndex].subHead[subHeadIndex],
                activity: newActivity
            };
        }
        setFormData(prevState => ({
            ...prevState,
            department_Array: updatedDepartments
        }));
        // console.log("department subhead update", updatedDepartments);
    };
    const getOptions = (dropdownName, type) => {
        if (type == 'department') {
            const selectedValuesArray = Object.values(selectedDepartment);
            //console.log(selectedDepartment);
            return department.filter(
                (option) =>
                    !selectedValuesArray.includes(option.id) ||
                    selectedDepartment[dropdownName] === option.id
            );
        }
        else if (type == 'subhead') {
            const selectedValuesArray = Object.values(selectedSubhead);
            //console.log(selectedSubhead);
            return subHead.filter(
                (option) =>
                    !selectedValuesArray.includes(option.id) ||
                    selectedSubhead[dropdownName] === option.id
            );
        }
    };
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };


    const calculateSubheadBalance = (id, balance) => {
        let total = 0;
        formData.department_Array.forEach(department => {
            department?.subHead?.forEach(item => {
                if(item.subhead_id === id) {
                    total += item.allocate_release || 0;
                }
            });
        });
        return balance - total;
    }


    return (
        <>
            <AddBudgetPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                handleSubmit={handleSubmit} scheme={scheme} subheadsList={subHead} financialYears={fy_list}
                department={department} activity={activity} subActivity={subActivity} subheadBudgetTotal={subheadBudgetTotal} pageType={'Add'} />
            <div>
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>{renderMessage()}</h4>
                            </div>
                            <div className="card p-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-sm-3">
                                                        <select
                                                            className="form-control"
                                                            name="scheme_id"
                                                            onChange={handleFormData}
                                                            value={formData.scheme_id}
                                                            disabled={toggle && !change}>
                                                            <option>Scheme Code</option>
                                                            {
                                                                schemeList.map((option) => {
                                                                    return (
                                                                        <option value={option?.id}>{option?.code}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <select
                                                            className="form-control"
                                                            name="financial_year_id"
                                                            onChange={handleFormData}
                                                            value={formData.financial_year_id}
                                                            disabled={toggle && !change}>
                                                            <option value="">Financial Year</option>
                                                            <option value="">---select year---</option>
                                                            {
                                                                fy_list.map((fy) => {
                                                                    return (
                                                                        <option
                                                                            value={fy.id}
                                                                        // selected={fy.id == fy_id ? true : false}
                                                                        >{fy.year}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    {(!id && !approveId) && <><div className="col-sm-3 d-flex justify-content-end pr-1">
                                                        <Button variant="primary" onClick={(e) => changeToggle(true)} disabled={toggle}>Add Scheme</Button>
                                                    </div>
                                                        <div className="col-sm-3 d-flex justify-content-start pl-1">
                                                            <Button variant="primary" onClick={(e) => handleConfirm(true)} disabled={!toggle}>Change Scheme</Button>
                                                        </div></>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <Form onSubmit={previewhandler}> */}
                            {formData.scheme_id && formData.financial_year_id && toggle && <div className="card p-3 mt-3">
                                <div className="row">
                                    {/* <h5 className="text-center">Scheme</h5> */}
                                    <div className="table-responsive custom-table" >
                                        <table className="table table-bordered" style={{ borderColor: 'gray' }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col">Scheme Code</th>
                                                    <th scope="col">Scheme Name</th>
                                                    <th scope="col">Type of Scheme</th>
                                                    <th scope="col">Provisioned</th>
                                                    <th scope="col">Released</th>
                                                    <th scope="col">Under Approval</th>
                                                    <th scope="col">Sanctioned</th>
                                                    <th scope="col">Released Balance</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>{scheme.code}</td>
                                                    <td>{scheme.name}</td>
                                                    <td>{scheme.type}</td>
                                                    <td>{scheme.provisional_budget}</td>
                                                    <td>{scheme.total_budget}</td>
                                                    <td>{scheme.utilised_budget}</td>
                                                    <td>0</td>
                                                    <td>{scheme.balance}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row form-mt-3">
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Budget Order Ref No <span className="text-danger">*</span>:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="budget_order_no"
                                                    value={formData.budget_order_no}
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input2" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Budget Order Date <span className="text-danger">*</span>:</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    name="budget_order_date"
                                                    value={formData.budget_order_date}
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center">
                                                {!formData?.budget_order_doc_url && <><label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Attach Budget Order <span className="text-danger">*</span>:</label>
                                                    <input type="file"
                                                        className='form-control'
                                                        name='order_attachment'
                                                        onChange={(e) => handleAttachmentChange(e, "order_attachment")}
                                                        accept=".pdf,.jpeg,.jpg,.png" multiple /></>}
                                                {formData?.budget_order_doc_url != null && formData?.budget_order_doc_url && <a className="mt-2 mx-2" href={formData?.budget_order_doc_url}
                                                    target='_blank'>order_attachment</a>}
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                {!formData?.budget_supporting_doc_url && <><label htmlFor="input2" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Attach Supporting</label>
                                                    <input type="file"
                                                        className='form-control'
                                                        name='supporting_attachment'
                                                        onChange={(e) => handleAttachmentChange(e, "supporting_attachment")}
                                                        accept=".pdf,.jpeg,.jpg,.png" multiple /></>}
                                                {formData?.budget_supporting_doc_url != null && formData?.budget_supporting_doc_url && <a className="mt-2 mx-2" href={formData?.budget_supporting_doc_url}
                                                    target='_blank'>supporting_attachment</a>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Provisioned Amount <span className="text-danger">*</span>:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="provision"
                                                    value={formData.provision}
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                            <div className="col-sm-6 d-flex align-items-center">
                                                <label htmlFor="input1" style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>Released Amount <span className="text-danger">*</span>:</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="release"
                                                    value={formData.release}
                                                    onChange={handleFormData}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 offset-md-2 mb-3">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group">
                                                    <label>L1 Remark <span className="text-danger">*</span>:</label>
                                                    <textarea id="remarl1" className="form-control" rows="2" name="l1_remark" value={formData.l1_remark} onChange={handleFormData}></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {formData.department_Array.map((item, index) => (<div className="card p-3">
                                    <>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label htmlFor="dropdown1" className='bold'>Select Department Code</label>
                                                    <select className="form-control" id="dropdown1" onChange={(e) => handleDepartmentChange(e.target.value, index, `department${index}`)} value={formData.department_Array[index].department_id}>
                                                        <option value="">---Select---</option>
                                                        {getOptions(`department${index}`, 'department').map((option) => (
                                                            <option key={option.id} value={option.id}>
                                                                {option.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-8">
                                                <table className="table table-striped table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th className='bold text-center'>Level</th>
                                                            <th className='bold text-center'>Department Name</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td className="text-center">{item.department_level}</td>
                                                            <td className="text-center">{item.department_name}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            {item.subHead.map((subhead, subheadIndex) => (<div className="card p-3">
                                                <div className="table-responsive custom-table">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">Sub-Head Code</th>
                                                                <th scope="col">Sub-Head Name</th>
                                                                <th scope="col">Provisioned</th>
                                                                <th scope="col">Released</th>
                                                                <th scope="col">Under Approval</th>
                                                                <th scope="col">Sanctioned</th>
                                                                <th scope="col">Released Balance</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr key={subheadIndex}>
                                                                <td>
                                                                    <select
                                                                        className="form-control"
                                                                        name='code'
                                                                        value={subhead.subhead_id}
                                                                        onChange={(e) => handleSubheadChange(e.target.value, subheadIndex, index, `subhead${subheadIndex}`)}
                                                                    >
                                                                        <option value="">---Select---</option>
                                                                        {getOptions(`subhead${subheadIndex}`, 'subhead').map((option) => (
                                                                            <option key={option.id} value={option.id}>
                                                                                {option.code}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </td>
                                                                <td>{subhead.name}</td>
                                                                <td>{subhead.provisional_budget}</td>
                                                                <td>{subhead.budget}</td>
                                                                <td>{subhead.utilised_budget}</td>
                                                                <td>0</td>
                                                                <td>{subhead.balance}</td>
                                                            </tr>
                                                            {
                                                                scheme.is_activity_active == 1 ? 
                                                                <tr>
                                                                <td colSpan="8">
                                                                    <div className='row'>
                                                                        <div className="table-responsive custom-table">
                                                                            <table className="table table-bordered">
                                                                                <thead className="text-center-custom">
                                                                                    <tr>
                                                                                        <th scope="col">Activity</th>
                                                                                        <th scope="col">Subactivity</th>
                                                                                        <th scope="col">Provisional Budget</th>
                                                                                        <th scope="col">Provisioned Allocation</th>
                                                                                        <th scope="col">Release Balance</th>
                                                                                        <th scope="col">Released Allocation</th>
                                                                                        <th scope="col">Action</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody className="text-center-custom">
                                                                                    {subhead.activity.map((activityData, activityIndex) => (
                                                                                        <tr key={activityIndex}>
                                                                                            <td>
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    name='activity_id'
                                                                                                    value={activityData.activity_id}
                                                                                                    disabled={!subhead.subhead_id}
                                                                                                    onChange={(e) => handleActivityChange(e.target.value, '', activityIndex, index, subheadIndex)}
                                                                                                >
                                                                                                    <option value="">---Select---</option>
                                                                                                    {activity.map(activity => {
                                                                                                        const exists = subhead.activities.find(e => e.subhead_activity_id == activity.id);
                                                                                                        if (exists) {
                                                                                                            return (
                                                                                                                <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>
                                                                                                <select
                                                                                                    className="form-control"
                                                                                                    name='subactivity_id'
                                                                                                    value={activityData.subactivity_id}
                                                                                                    disabled={!subhead.subhead_id}
                                                                                                    onChange={(e) => handleActivityChange(activityData.activity_id, e.target.value, activityIndex, index, subheadIndex)}
                                                                                                >
                                                                                                    <option value="">---Select---</option>
                                                                                                    {findSubActivitiesById(activityData.activity_id).map(e => {
                                                                                                        const exists = subhead.activities.find(ea => ea.subhead_activity_id == activityData.activity_id && ea.subhead_subactivity_id == e.id);
                                                                                                        if (exists) {
                                                                                                            return (
                                                                                                                <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                                </select>
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    value={activityData.subhead_provisional_budget}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    name='provisional_budget'
                                                                                                    placeholder="Enter Provision Budget"
                                                                                                    value={activityData.provisional_budget}
                                                                                                    disabled={!activityData.subactivity_id}
                                                                                                    onChange={(e) => handleSubheadBudgetFormData(index, subheadIndex, activityIndex, e)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    value={activityData.subhead_budget}
                                                                                                    readOnly={true}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control"
                                                                                                    name='budget'
                                                                                                    placeholder="Enter Budget"
                                                                                                    value={activityData.budget}
                                                                                                    disabled={!activityData.subactivity_id}
                                                                                                    onChange={(e) => handleSubheadBudgetFormData(index, subheadIndex, activityIndex, e)}
                                                                                                />
                                                                                            </td>
                                                                                            <td>
                                                                                                <button className="btn btn-primary mx-2" onClick={(e) => addNewActivity(index, subheadIndex)}>
                                                                                                    <AddIcon />
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-danger mx-2"
                                                                                                    onClick={(e) => deleteNewActivity(index, subheadIndex, activityIndex)}
                                                                                                >
                                                                                                    <DeleteForever />
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    ))}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr> : 
                                                            <tr>
                                                                <td colSpan="8">
                                                                    <div className='row'>
                                                                        <div className="table-responsive custom-table">
                                                                            <table className="table table-bordered">
                                                                                <thead className="text-center-custom">
                                                                                    <tr>
                                                                                        <th scope="col">Provisioned Allocation</th>
                                                                                        <th scope="col">Released Allocation</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td scope='col'>
                                                                                            <input 
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Budget"
                                                                                                name="allocate_provision"
                                                                                                onChange={e => changeSubheadBudget(index, subheadIndex, e)}
                                                                                                value={subhead?.allocate_provision}
                                                                                            />
                                                                                        </td>
                                                                                        <td scope='col'>
                                                                                            <input 
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                placeholder="Enter Budget"
                                                                                                name="allocate_release"
                                                                                                onChange={e => changeSubheadBudget(index, subheadIndex, e)}
                                                                                                value={subhead?.allocate_release}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            }
                                                            
                                                        </tbody>
                                                    </table>
                                                    <div className="table-responsive custom-table">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th></th>
                                                                    <th scope="col">Provision</th>
                                                                    <th scope="col">Release Balance</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Subhead Initial</td>
                                                                    <td>{subhead.provisional_budget | 0}</td>
                                                                    <td>{subhead.balance | 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Total Allocation</td>
                                                                    <td>{subhead?.allocate_provision | 0}</td>
                                                                    <td>{subhead?.allocate_release | 0}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td></td>
                                                                    <td>Budget Balance</td>
                                                                    <td>{subhead.provisional_budget - subhead?.allocate_provision | 0}</td>
                                                                    <td>{subhead.balance - subhead?.allocate_release | 0}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>))}
                                        </div>
                                        <div className="row justify-content-end">
                                            <div className="col-sm-6 d-flex justify-content-end">
                                                {subheadCount > 0 && <Button variant="primary" onClick={() => handleAddSubHead(index)}>Add SubHead</Button>}
                                                {formData.department_Array[index].subHead.length > 1 && <Button variant="danger" onClick={() => handleDeleteSubHead(index)} className="ml-2">Delete SubHead</Button>}
                                            </div>
                                        </div>
                                    </>
                                </div>))}
                                <div className="col-sm-12 align-items-end d-flex justify-content-end">
                                    {departmentCount > 0 && <Button variant="primary" onClick={() => handleAddDepartment()} className="button-margin-right">Add Department</Button>}
                                    {formData.department_Array.length > 1 && <Button variant="danger" onClick={() => handleDeleteDepartment()}>Delete Department</Button>}
                                </div>
                                <br></br>
                                <div className='row justify-content-center'>
                                    <div className='col-md-8'>
                                        <table className="table table-bordered mx-auto w-90">
                                            <thead className="text-center-custom">
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col">Provisioned</th>
                                                    <th scope="col">Released</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center-custom">
                                                <tr>
                                                    <td className='bold'>Initial Scheme Balance</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='provisionalBudget'
                                                            readOnly={true}
                                                            value={scheme.provisional_budget}
                                                            placeholder="Enter Provision Budget" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='releasedBudget'
                                                            readOnly={true}
                                                            value={scheme.balance}
                                                            placeholder="Enter Release Budget" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='bold'>Total Allocation of Scheme</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='provisionalBudget'
                                                            readOnly={true}
                                                            value={subheadBudgetTotal.current.allocateProvision || 0}
                                                            placeholder="Enter Provision Budget" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='releasedBudget'
                                                            readOnly={true}
                                                            value={subheadBudgetTotal.current.allocateRelease || 0}
                                                            placeholder="Enter Release Budget" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='bold'>Scheme Balance</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='provisionalBudget'
                                                            readOnly={true}
                                                            value={scheme.provisional_budget - subheadBudgetTotal.current.allocateProvision || 0}
                                                            placeholder="Enter Provision Budget" />
                                                    </td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            className="form-control"
                                                            name='releasedBudget'
                                                            readOnly={true}
                                                            value={scheme.balance - subheadBudgetTotal.current.allocateRelease || 0}
                                                            placeholder="Enter Release Budget" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="row mt-3 justify-content-center">
                                    <div className="col-auto">
                                        {/* <button type="button" className="btn btn-secondary mx-2">Save Draft</button> */}
                                        <button type="button" className="btn btn-primary mx-2" onClick={previewhandler}>Submit</button>
                                    </div>
                                </div>
                            </div>}
                            {/* </Form> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={showConfirm} onHide={() => handleConfirm(false)} centered>
                <Modal.Header closeButton={() => handleConfirm(false)}>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>If you want to change the scheme, you will lose any filled data. Are you sure you want to continue?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => confirmSubmit()}>
                        YES
                    </Button>
                    <Button variant="danger" onClick={() => handleConfirm(false)}>
                        No
                    </Button>
                </Modal.Footer>
            </Modal></>
    );
};
export default AllocateBudget;